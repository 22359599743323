import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hunts for Heroes helps local veteran`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "715px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6c9e4913819ffd5aa337e319fbc17cdb/d33ce/hunts-for-heros-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGvKFxVQgP/xAAaEAADAQADAAAAAAAAAAAAAAABAgMAERIh/9oACAEBAAEFAmqeEqSnfVBKBqZj7//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMhARIf/aAAgBAQAGPwKxvDXBRUUVP//EABsQAQEBAAIDAAAAAAAAAAAAAAERADFhQXGh/9oACAEBAAE/IQP6uC1e7r8sx8OlxCDTByR6d//aAAwDAQACAAMAAAAQpC//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCR/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QpT//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAxUSFBYXHw/9oACAEBAAE/EEtgiVIecpq9IaOXdjhcWMZPjvhkgFFOk2nvBulwBn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of hunter with prey",
            "title": "Image of hunter with prey",
            "src": "/static/6c9e4913819ffd5aa337e319fbc17cdb/d33ce/hunts-for-heros-1.jpg",
            "srcSet": ["/static/6c9e4913819ffd5aa337e319fbc17cdb/e07e9/hunts-for-heros-1.jpg 200w", "/static/6c9e4913819ffd5aa337e319fbc17cdb/066f9/hunts-for-heros-1.jpg 400w", "/static/6c9e4913819ffd5aa337e319fbc17cdb/d33ce/hunts-for-heros-1.jpg 715w"],
            "sizes": "(max-width: 715px) 100vw, 715px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`United States Marine Corps Cpl. and Williams resident Neil Schalk had a holiday experience he’ll never forget, thanks to the Arizona Elk Society’s Hunts for Heroes Program after receiving a last minute Arizona Desert Sheep tag for free.`}</p>
    <p>{`The program, a partnership between the Arizona Elk Society (AES) and Arizona Game and Fish, connects veterans with disabilities with Arizona hunting outfitters to get veterans out of their routine and enjoying the outdoors.`}</p>
    <p>{`In 2017, Program Director Dave Holbrook received a donated Arizona Desert Sheep tag. Time was a factor since the season ended Dec. 31, meaning the Arizona Elk Society and volunteers, would have to organize the hunt immediately. Holbrook reached out to volunteers who dropped everything during the busy holiday season to volunteer fewer than 12 hours after the initial request. Additionally, Schalk was contacted to be a participant in Hunts for Heroes.`}</p>
    <p>{`Schalk, who lives in Williams, carefully contemplated the opportunity to participate in a hunt despite the holiday season and short time frame.`}</p>
    <p>{`“After considering the magnitude of an Arizona sheep tag and a conversation with Hunts for Heroes coordinator Tom Wagner, we decided to give it a go,” Schalk said.`}</p>
    <p>{`Schalk met with Wagner the next day and together they drove to Superior, Arizona. The two met with seven volunteers from CouesWhitetail.com, a local online forum created by a wildlife biologist for hunters and wildlife conservationists. Forum members rallied equipment and resources to participate group met at the trailhead and glassed the mountain looking for Bighorn Sheep. After spotting two mature rams, Schalk and three volunteers hiked Picketpost Mountain.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/290235a35c487e790d682012997edca1/63a7e/hunts-for-heros-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABmNxkM06KP//EABoQAAMBAAMAAAAAAAAAAAAAAAACAwESMTL/2gAIAQEAAQUClHkPBcG9LRsGY3v/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECERAhIjFR/9oACAEBAAY/AuTo1JM10R8ReP/EABwQAQACAwADAAAAAAAAAAAAAAEAESExQVFx8f/aAAgBAQABPyGy8OO3AdgcxACPRGPogWUvzLrXbP/aAAwDAQACAAMAAAAQKx//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEQD/2gAIAQIBAT8QTN//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMVFxkfD/2gAIAQEAAT8QPUjVmxKcfbMCX6EO3QLja2vTHCgtA0+WR8MFHG2XDBKVmf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of hunter with prey",
            "title": "Image of hunter with prey",
            "src": "/static/290235a35c487e790d682012997edca1/63a7e/hunts-for-heros-2.jpg",
            "srcSet": ["/static/290235a35c487e790d682012997edca1/e07e9/hunts-for-heros-2.jpg 200w", "/static/290235a35c487e790d682012997edca1/066f9/hunts-for-heros-2.jpg 400w", "/static/290235a35c487e790d682012997edca1/63a7e/hunts-for-heros-2.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`“The rams had spotted us and were looking straight down at us,” Schalk said. “Although the wind was in our favor, we decided trying to get closer would probably send them over the top. After a few minutes of fumbling around trying to get a solid rest, we were able to take a solid shot. One shot and the ram rolled downhill out of sight. It took us about 45 minutes to cover those 500 yards, and I was speechless when we walked up on the monster sheep. The guys down below could hear our celebration all the way from their glassing location.”`}</p>
    <p>{`They took photos, quartered the ram and headed back down the mountain, the way lit by headlamps.`}</p>
    <p>{`Schalk said he is still in disbelief of the coordination, volunteers and harvesting of a trophy desert bighorn.`}</p>
    <p>{`“I cannot thank the volunteers from CouesWhitetail.com and Arizona Elk Society’s Hunts for Heroes program enough,” Schalk said. “It was an unbelievable team effort and I truly appreciate the compassion and footwork that made this hunt possible. It made me feel greatly appreciated. Many combat wounded veterans say the hardest thing isn’t the combat tour itself, but the transition back to normal life. For someone like myself, this hunt has proven to be a great healing experience, and a great step toward normalization.”
Schalk joins a growing list of veterans helped by the program for veterans with a significant mobility disability. Many of these veterans remain at home, disengaged from society and some losing hope. The three- to seven-day adventures are more than a hunting trip, they provide a sense of normalcy that the veterans thought they lost and provides new perspective on what their life can look like with a disability. Fifty percent of the veterans participating in the AES Hunts for Heroes program have never experienced the hunt.`}</p>
    <p>{`Hunts for Heroes is projected to provide more than 60 hunts in 2017.`}</p>
    <p>{`“We’re all coming together to help the healing process for these warriors,” Holbrook said. “It’s amazing how many people love and respect our veterans. It’s very heartwarming and gratifying.”`}</p>
    <p>{`The program is looking to help even more Arizona wounded veterans by accepting individual donations, corporate sponsorships and volunteers. Oftentimes, those who volunteer for the program are veterans themselves, which creates an immediate sense of family for the wounded veterans and a quick bond on the expedition. More information is available from Arizona Elk Society Executive Director Steve Clark at `}<a parentName="p" {...{
        "href": "mailto:stevec@ArizonaElkSociety.org"
      }}>{`stevec@ArizonaElkSociety.org`}</a>{`.`}</p>
    <p>{`More information about nominating a veteran with a significant mobility disability is available at `}<a parentName="p" {...{
        "href": "https://www.arizonaelksociety.org/hunts-for-heroes"
      }}>{`https://www.arizonaelksociety.org/hunts-for-heroes`}</a>{` or from Dave Holbrook at `}<a parentName="p" {...{
        "href": "mailto:DaveH@ArizonaElkSociety.org"
      }}>{`DaveH@ArizonaElkSociety.org`}</a>{`.`}</p>
    <p>{`The Arizona Elk Society is committed to conserving and enhancing wildlife habitat in Arizona, protecting sound wildlife management and habitat through partnering with government agencies and other organizations and implementing special programs for youth education regarding conservation, hunting and outdoor activities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      